import { GetImageUrl } from 'designsystem/src/helpers/getImageUrl';
import { heroMapper } from 'helpers/mappers';
import Head from 'next/head';
import { HeroCard } from 'ui/components/2-molecules/Card/HeroCard/HeroCard';

export const HeroCardFeature: React.FC<Content.HeroCard> = (props) => {
	const { image, video } = props.content.properties;
	return (
		<>
			<HeroCard {...heroMapper(props)} />
			<Head>
				{image?.url && <link rel="preload" as="image" href={GetImageUrl({ url: image.url, format: 'webp', width: 960 })} />}
				{video?.url && <link rel="preload" as="video" href={video.url} />}
			</Head>
		</>
	);
};
